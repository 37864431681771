
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { RefIssueFormData } from '@/types/appcontracts/RefIssueFormData'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { ICustomersSearchMapping } from '@/types/interfaces/ICustomersSearchMapping'
import { computed, defineComponent, reactive, ref, toRaw } from 'vue'
import { useStore } from 'vuex'
import * as actions from '@/store/actions.type'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 8 },
}

interface IRefGenerator {
  refType: 'awb' | 'rfn'
  stepType: 'increment' | 'mod'
  stepValue: number
  pfx: string | null
  startNumber: number
  totalRefs: number
}

const getInitialState = () => {
  const initialFormState: RefIssueFormData = {
    customerId: null,
    awbs: [],
    rfns: [],
    reIssue: false,
  }

  return initialFormState
}

const getInitialGenerator = () => {
  const genn: IRefGenerator = {
    refType: 'awb',
    stepType: 'increment',
    stepValue: 1,
    pfx: null,
    startNumber: 1,
    totalRefs: 1,
  }

  return genn
}

export default defineComponent({
  setup() {
    const store = useStore()
    const formRef = ref()
    const formState = reactive(getInitialState())
    const generator = reactive(getInitialGenerator())
    const isLoading = ref<boolean>(false)
    const isGenerating = ref<boolean>(false)
    const operation = ref<'create' | 'delete'>('create')
    const isCustDelete = computed(
      () =>
        operation.value === 'delete' &&
        formState.customerId !== null &&
        formState.customerId !== undefined,
    )
    const rules = computed(() => {
      return {
        customerId: [
          {
            required: operation.value === 'create' ? true : false,
            message: 'Please select a Customer',
            trigger: 'blur',
            type: 'string',
          },
        ],
      }
    })

    const customersOptions = computed(() => {
      return store.state.customers.customersSearchList?.map((cust: ICustomersSearchMapping) => {
        return {
          value: cust.id,
          label: `${cust.displayName} (${cust.cid})`,
          key: cust.id,
        }
      })
    })

    const handleGenerate = () => {
      isGenerating.value = true
      const refSet = new Set<string>()
      let st = generator.startNumber
      // const nums = []
      for (let i = 0; i < generator.totalRefs; i++) {
        if (i !== 0) {
          st += generator.stepValue
        }
        const str = generator.pfx ? `${generator.pfx}${st}` : `${st}`
        refSet.add(str)
        // nums.push(str)
      }
      if (generator.refType === 'awb') {
        formState.awbs.forEach((x) => refSet.add(x))
        // const dd = new Set([...formState.awbs, ...nums])
        formState.awbs = [...refSet]
      } else {
        formState.rfns.forEach((x) => refSet.add(x))
        // const dd = new Set([...formState.rfns, ...nums])
        // formState.rfns = [...dd]
        formState.rfns = [...refSet]
      }
      isGenerating.value = false
    }

    const handleFinish = async () => {
      isLoading.value = true
      const docOperation: DocumentOperation<RefIssueFormData> = {
        action: operation.value === 'create' ? DocumentActions.CREATE : DocumentActions.DELETE,
        id: null,
        payload: toRaw(formState),
        audit: null,
      }
      const isSuccess = await store.dispatch(
        `customers/${actions.CustomersAction.SET_OR_DELETE_REFISSUE}`,
        docOperation,
      )
      isLoading.value = false
      if (isSuccess) {
        resetForm()
      }
    }

    const resetForm = () => {
      Object.assign(formState, getInitialState())
      Object.assign(generator, getInitialGenerator())
      isLoading.value = false
    }

    return {
      formRef,
      formState,
      labelCol,
      wrapperCol,
      customersOptions,
      handleFinish,
      rules,
      generator,
      isLoading,
      handleGenerate,
      isGenerating,
      operation,
      isCustDelete,
    }
  },
})
