<template>
  <div>
    <div class="d-flex flex-wrap border-bottom">
      <div class="mt-1 mr-3">
        <h4>Customer Reference Issue</h4>
      </div>
    </div>
    <div class="mt-4">
      <a-form
        ref="formRef"
        :rules="rules"
        :model="formState"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        @finish="handleFinish"
        label-align="left"
        :colon="false"
      >
        <div class="card card-top">
          <div class="card-body">
            <a-row>
              <a-col :md="13" :xs="24">
                <div style="border-right: 1px solid #f0f0f0" class="pr-5">
                  <a-form-item
                    label="Operation"
                    name="operation"
                    :wrapper-col="{ sm: { span: 16 }, xs: { span: 24 } }"
                    :label-col="{ sm: { span: 8 }, xs: { span: 24 } }"
                  >
                    <a-radio-group v-model:value="operation">
                      <a-radio-button value="create">CREATE</a-radio-button>
                      <a-radio-button value="delete">DELETE</a-radio-button>
                    </a-radio-group>
                  </a-form-item>

                  <a-form-item
                    label="Customer"
                    name="customerId"
                    :wrapper-col="{ sm: { span: 16 }, xs: { span: 24 } }"
                    :label-col="{ sm: { span: 8 }, xs: { span: 24 } }"
                  >
                    <a-select
                      placeholder="Please select customer"
                      v-model:value="formState.customerId"
                      :show-search="true"
                      option-filter-prop="label"
                      allow-clear
                      :options="customersOptions"
                    />
                  </a-form-item>
                  <a-form-item
                    label="AWBs"
                    name="awbs"
                    :wrapper-col="{ sm: { span: 16 }, xs: { span: 24 } }"
                    :label-col="{ sm: { span: 8 }, xs: { span: 24 } }"
                  >
                    <a-select
                      mode="tags"
                      placeholder="Please select"
                      v-model:value="formState.awbs"
                      style="width: 100%"
                      :token-separators="[',']"
                      :disabled="isCustDelete"
                      allow-clear
                    >
                    </a-select>
                  </a-form-item>
                  <a-form-item
                    label="RFNs"
                    name="rfns"
                    :wrapper-col="{ sm: { span: 16 }, xs: { span: 24 } }"
                    :label-col="{ sm: { span: 8 }, xs: { span: 24 } }"
                  >
                    <a-select
                      mode="tags"
                      placeholder="Please select"
                      v-model:value="formState.rfns"
                      style="width: 100%"
                      :token-separators="[',']"
                      :disabled="isCustDelete"
                      allow-clear
                    >
                    </a-select>
                  </a-form-item>
                  <a-form-item
                    label="ReIssue ?"
                    name="reIssue"
                    :wrapper-col="{ sm: { span: 16 }, xs: { span: 24 } }"
                    :label-col="{ sm: { span: 8 }, xs: { span: 24 } }"
                  >
                    <a-switch
                      v-model:checked="formState.reIssue"
                      :disabled="operation === 'delete'"
                    />
                  </a-form-item>
                </div>
              </a-col>
              <a-col :md="10" :xs="24" :offset="1">
                <!-- style="border-left: 1px solid #f0f0f0" class="pl-5" -->
                <div class="d-flex justify-content-between">
                  <a-radio-group v-model:value="generator.refType">
                    <a-radio-button value="awb">AWB</a-radio-button>
                    <a-radio-button value="rfn">RFN</a-radio-button>
                  </a-radio-group>
                  <a-radio-group v-model:value="generator.stepType">
                    <a-radio-button value="increment">Increment</a-radio-button>
                    <a-radio-button value="mod" disabled>MOD</a-radio-button>
                  </a-radio-group>
                  <a-tooltip :title="`Enter ${generator.stepType} Value`">
                    <a-input-number
                      v-model:value="generator.stepValue"
                      placeholder="Step Value"
                      :min="1"
                      allow-clear
                    />
                  </a-tooltip>
                </div>
                <div class="mt-5">
                  <a-form-item
                    label="Prefix"
                    name="prefix"
                    :wrapper-col="{ sm: { span: 16 }, xs: { span: 24 } }"
                    :label-col="{ sm: { span: 8 }, xs: { span: 24 } }"
                  >
                    <a-input v-model:value="generator.pfx" placeholder="Enter Prefix" allow-clear />
                  </a-form-item>
                  <a-form-item
                    label="Start Number"
                    name="startNumber"
                    :wrapper-col="{ sm: { span: 16 }, xs: { span: 24 } }"
                    :label-col="{ sm: { span: 8 }, xs: { span: 24 } }"
                  >
                    <a-input-number
                      v-model:value="generator.startNumber"
                      placeholder="Enter #"
                      :style="{ width: '100%' }"
                      :min="1"
                      allow-clear
                    />
                  </a-form-item>
                  <a-form-item
                    label="Total Number"
                    name="totalRefs"
                    :wrapper-col="{ sm: { span: 16 }, xs: { span: 24 } }"
                    :label-col="{ sm: { span: 8 }, xs: { span: 24 } }"
                  >
                    <a-input-number
                      v-model:value="generator.totalRefs"
                      placeholder="Total refs"
                      :style="{ width: '100%' }"
                      :min="1"
                      :max="1000"
                      allow-clear
                    />
                  </a-form-item>
                </div>
                <div class="mt-4">
                  <a-button
                    class="btn btn-primary px-5 mr-3"
                    :loading="isGenerating"
                    @click="handleGenerate"
                    :disabled="isCustDelete"
                  >
                    Generate
                  </a-button>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
        <div>
          <a-button
            html-type="submit"
            :loading="isLoading"
            class="btn btn-primary px-5 mr-3"
            :disabled="
              formState.awbs?.length === 0 && formState.rfns?.length === 0 && !isCustDelete
            "
          >
            {{ operation === 'create' ? 'Save' : 'Delete' }}
          </a-button>
          <a-button class="btn btn-light px-5  mr-3" @click.prevent="$router.go(-1)">
            Cancel
          </a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script lang="ts">
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { RefIssueFormData } from '@/types/appcontracts/RefIssueFormData'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { ICustomersSearchMapping } from '@/types/interfaces/ICustomersSearchMapping'
import { computed, defineComponent, reactive, ref, toRaw } from 'vue'
import { useStore } from 'vuex'
import * as actions from '@/store/actions.type'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 8 },
}

interface IRefGenerator {
  refType: 'awb' | 'rfn'
  stepType: 'increment' | 'mod'
  stepValue: number
  pfx: string | null
  startNumber: number
  totalRefs: number
}

const getInitialState = () => {
  const initialFormState: RefIssueFormData = {
    customerId: null,
    awbs: [],
    rfns: [],
    reIssue: false,
  }

  return initialFormState
}

const getInitialGenerator = () => {
  const genn: IRefGenerator = {
    refType: 'awb',
    stepType: 'increment',
    stepValue: 1,
    pfx: null,
    startNumber: 1,
    totalRefs: 1,
  }

  return genn
}

export default defineComponent({
  setup() {
    const store = useStore()
    const formRef = ref()
    const formState = reactive(getInitialState())
    const generator = reactive(getInitialGenerator())
    const isLoading = ref<boolean>(false)
    const isGenerating = ref<boolean>(false)
    const operation = ref<'create' | 'delete'>('create')
    const isCustDelete = computed(
      () =>
        operation.value === 'delete' &&
        formState.customerId !== null &&
        formState.customerId !== undefined,
    )
    const rules = computed(() => {
      return {
        customerId: [
          {
            required: operation.value === 'create' ? true : false,
            message: 'Please select a Customer',
            trigger: 'blur',
            type: 'string',
          },
        ],
      }
    })

    const customersOptions = computed(() => {
      return store.state.customers.customersSearchList?.map((cust: ICustomersSearchMapping) => {
        return {
          value: cust.id,
          label: `${cust.displayName} (${cust.cid})`,
          key: cust.id,
        }
      })
    })

    const handleGenerate = () => {
      isGenerating.value = true
      const refSet = new Set<string>()
      let st = generator.startNumber
      // const nums = []
      for (let i = 0; i < generator.totalRefs; i++) {
        if (i !== 0) {
          st += generator.stepValue
        }
        const str = generator.pfx ? `${generator.pfx}${st}` : `${st}`
        refSet.add(str)
        // nums.push(str)
      }
      if (generator.refType === 'awb') {
        formState.awbs.forEach((x) => refSet.add(x))
        // const dd = new Set([...formState.awbs, ...nums])
        formState.awbs = [...refSet]
      } else {
        formState.rfns.forEach((x) => refSet.add(x))
        // const dd = new Set([...formState.rfns, ...nums])
        // formState.rfns = [...dd]
        formState.rfns = [...refSet]
      }
      isGenerating.value = false
    }

    const handleFinish = async () => {
      isLoading.value = true
      const docOperation: DocumentOperation<RefIssueFormData> = {
        action: operation.value === 'create' ? DocumentActions.CREATE : DocumentActions.DELETE,
        id: null,
        payload: toRaw(formState),
        audit: null,
      }
      const isSuccess = await store.dispatch(
        `customers/${actions.CustomersAction.SET_OR_DELETE_REFISSUE}`,
        docOperation,
      )
      isLoading.value = false
      if (isSuccess) {
        resetForm()
      }
    }

    const resetForm = () => {
      Object.assign(formState, getInitialState())
      Object.assign(generator, getInitialGenerator())
      isLoading.value = false
    }

    return {
      formRef,
      formState,
      labelCol,
      wrapperCol,
      customersOptions,
      handleFinish,
      rules,
      generator,
      isLoading,
      handleGenerate,
      isGenerating,
      operation,
      isCustDelete,
    }
  },
})
</script>

<style scoped>
.ant-select-multiple {
  max-height: 200px;
  overflow: auto;
}
</style>
